import React, { useState, useEffect, useLayoutEffect } from "react"
import anime from "animejs"

function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([Math.max(document.documentElement.clientWidth, window.innerWidth || 0), Math.max(document.documentElement.clientHeight, window.innerHeight || 0)])
    }
    window.addEventListener('resize', updateSize)
    updateSize();
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

export function Sky() {
  const num = 60

  const [vw, vh] = useWindowSize()

  const starryNight = () => {
    anime({
      targets: ["#sky .star"],
      opacity: [
        {
          duration: 700,
          value: "0"
        },
        {
          duration: 700,
          value: "1"
        }
      ],
      easing: "linear",
      loop: true,
      delay: (el, i) => 50 * i
    });
  }

  const shootingStars = () => {
    anime({
      targets: ["#shootingstars .wish"],
      easing: "linear",
      loop: true,
      delay: (el, i) => 1000 * i,
      opacity: [
        {
          duration: 700,
          value: "1"
        }
      ],
      width: [
        {
          value: "150px"
        },
        {
          value: "0px"
        }
      ],
      translateX: 500
    });
  }

  const randomRadius = () => {
    return Math.random() * 0.7 + 0.6;
  }

  const getRandomX = () => {
    return Math.floor(Math.random() * Math.floor(vw)).toString()
  }

  const getRandomY = () => {
    return Math.floor(Math.random() * Math.floor(vh)).toString()
  }

  const generateSkyArray = () => {
    return [...Array(num)].map((x, y) => (
      <circle
        cx={getRandomX()}
        cy={getRandomY()}
        r={randomRadius()}
        stroke="none"
        strokeWidth="0"
        fill="white"
        key={y}
        className="star"
      />
    ))
  }

  const generateStarsArray = () => {
    return [...Array(10)].map((x, y) => (
      <div
        key={y}
        className="wish"
        style={{
          left: `${getRandomY()}px`,
          top: `${getRandomX()}px`
        }}
      />
    ))
  }

  const [skyArray, setSkyArray] = useState(generateSkyArray())
  const [starsArray, setStarsArray] = useState(generateStarsArray())

  useEffect(() => {
    starryNight()
    shootingStars()
  }, [])

  useEffect(() => {
    setSkyArray(generateSkyArray())
    setStarsArray(generateStarsArray())
  }, [vw, vh])

  const logo = new URL('../logo.svg', import.meta.url);

  return (
    <div>
      <div className="content">
        <div className="centerInfo">
          <img className="mainLogo" src={logo} alt="Logo" width="600" />
          <h3>Проекты</h3>
          <p><a className="landing-link" href="https://vkmusicbot.megaworld.space/">VK Music Bot</a> – лучший Discord-бот для прослушивания музыки из ВК</p>
        </div>
      </div>
      <svg id="sky">
        {skyArray}
      </svg>
      <div id="shootingstars">
        {starsArray}
      </div>
    </div>
  )
}